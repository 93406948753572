/* General Styles */
body {
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f4f4f4;
    color: #333;
}

h1 {
    text-align: center;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-weight:800;
    margin: 10px 0;
}

a {
    text-decoration: none;
    color: inherit;
}

button {
    cursor: pointer;
    padding: 10px 20px;
    background-color: #007bff;
    border: none;
    color: white;
    border-radius: 4px;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

button:hover {
    background-color: #0056b3;
}

/* Blog Grid Styles */
.blog-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    gap: 25px;
    padding: 40px;
    max-width: 1200px;
    margin: 5px auto;
}

/* Blog Item Styles */
.blog-item {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* Ensures button stays at the bottom */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    padding-bottom: 15px;
}

.blog-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.blog-item img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.blog-item h2 {
    font-size: 22px;
    margin: 16px 20px;
    color: #333;
}

.blog-item p {
    font-size: 16px;
    margin: 0 20px 20px;
    color: #666;
    flex-grow: 1;
    /* Allows consistent height for the text and pushes button to bottom */
}

.blog-item button {
    margin: 0 auto;
    /* Centers the button */
    width: calc(100% - 40px);
    /* Leaves space for padding */
    padding: 12px;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    font-weight: bold;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.blog-item button:hover {
    background-color: #0056b3;
}

/* Blog Detail Styles */
.blog-detail {
    max-width: 800px;
    margin: 40px auto;
    background-color: white;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.blog-detail h1 {
    font-size: 36px;
    margin-bottom: 20px;
    color: #333;
}

.blog-detail p {
    font-size: 18px;
    line-height: 1.6;
    color: #555;
}

/* Blog Detail Image */
.blog-detail img {
    width: 100%;
    height: 400px;
    object-fit: cover;
    border-radius: 8px;
}

/* Comments Section */
.comments-section {
    margin-top: 40px;
    background-color: #f8f9fa;
    padding: 20px;
    border-radius: 8px;
}

.comments-section h3 {
    margin-bottom: 20px;
    font-size: 22px;
}

.comments-section textarea {
    width: 100%;
    padding: 15px;
    font-size: 16px;
    border-radius: 4px;
    border: 1px solid #ccc;
    margin-bottom: 20px;
    transition: border-color 0.3s ease;
}

.comments-section textarea:focus {
    border-color: #007bff;
    outline: none;
}

.comments-section button {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.comments-list {
    margin-top: 20px;
}

.comment-item {
    padding: 15px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-bottom: 20px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.comment-item p {
    margin: 0;
    font-size: 16px;
    color: #555;
}

.comment-item span {
    display: block;
    margin-top: 10px;
    font-size: 14px;
    color: #999;
    text-align: right;
}

/* Responsive Styles */
@media screen and (max-width: 768px) {
    .blog-detail {
        padding: 20px;
    }

    .blog-detail h1 {
        font-size: 28px;
    }

    .blog-item h2 {
        font-size: 20px;
    }

    .blog-item button {
        font-size: 16px;
    }

    .comments-section {
        padding: 15px;
    }
}