/* General Styles */
body {
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f9f9f9;
    color: #333;
}

main {
    padding: 50px 20px 100px;
}

/* Main Heading */
.main-heading {
    text-align: center;
    font-size: 36px;
    color: #007bff;
    margin-bottom: 40px;
    opacity: 0;
    transform: translateY(-20px);
    animation: fadeInDown 1s forwards;
}

/* Section Headings */
.section-heading {
    font-size: 28px;
    color: #007bff;
    margin-bottom: 20px;
    text-align: center;
    position: relative;
    padding-bottom: 10px;
}

.section-heading::after {
    content: '';
    width: 60px;
    height: 3px;
    background-color: #007bff;
    display: block;
    margin: 10px auto 0;
    border-radius: 2px;
}

/* Section Buttons */
.section-buttons {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    gap: 15px;
}

.section-button {
    background-color: #e0e0e0;
    border: none;
    padding: 12px 25px;
    cursor: pointer;
    font-size: 16px;
    border-radius: 25px;
    transition: background-color 0.3s ease, transform 0.3s ease;
    position: relative;
    overflow: hidden;
}

.section-button::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 300%;
    height: 300%;
    background-color: rgba(0, 123, 255, 0.2);
    transition: all 0.6s ease;
    transform: translate(-50%, -50%) scale(0);
    border-radius: 50%;
}

.section-button:hover::after {
    transform: translate(-50%, -50%) scale(1);
}

.section-button.active {
    background-color: #007bff;
    color: white;
    box-shadow: 0 4px 15px rgba(0, 123, 255, 0.4);
    transform: translateY(-2px);
}

.section-button:hover {
    transform: translateY(-2px);
}

/* Videos Section */
.video-main {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 30px;
    justify-items: center;
}

.video-card {
    background-color: #fff;
    width: 100%;
    max-width: 350px;
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
}

.video-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.2);
}

.video-card iframe {
    width: 100%;
    height: 200px;
    border: none;
    border-bottom: 1px solid #ddd;
}

.video-title {
    font-size: 18px;
    color: #333;
    margin: 15px;
    text-align: left;
}

.video-description {
    font-size: 14px;
    color: #555;
    margin: 0 15px 15px;
    text-align: left;
    line-height: 1.5;
}

/* Quotes Section */
.quotes-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.quote-card {
    background-color: #fff;
    width: 100%;
    max-width: 600px;
    padding: 20px 25px;
    border-left: 5px solid #007bff;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.quote-card:hover {
    transform: translateY(-3px);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
}

.quote-text {
    font-size: 18px;
    font-style: italic;
    color: #555;
    margin-bottom: 10px;
    position: relative;
    padding-left: 20px;
}

.quote-text::before {
    content: '“';
    font-size: 30px;
    position: absolute;
    left: 0;
    top: -5px;
    color: #007bff;
}

.quote-author {
    font-size: 16px;
    color: #888;
    text-align: right;
    margin-right: 10px;
}

/* Animations */
@keyframes fadeInDown {
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Responsive Styling */
@media (max-width: 1200px) {
    .video-card {
        max-width: 300px;
    }
}

@media (max-width: 992px) {
    .section-buttons {
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }

    .section-button {
        width: 150px;
        text-align: center;
    }

    .video-card {
        max-width: 100%;
    }
}

@media (max-width: 768px) {
    .video-card iframe {
        height: 180px;
    }

    .quote-card {
        padding: 15px 20px;
    }

    .quote-text {
        font-size: 16px;
    }

    .quote-author {
        font-size: 14px;
    }
}

@media (max-width: 576px) {
    .video-card iframe {
        height: 150px;
    }

    .video-title {
        font-size: 16px;
    }

    .video-description {
        font-size: 13px;
    }

    .quote-text {
        font-size: 15px;
    }

    .section-button {
        width: 120px;
        padding: 10px 20px;
    }
}
