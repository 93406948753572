.footer {
    background-color: #00274d;
    color: #ffffff;
    padding: 20px 0px;
    text-align: center;
    position: relative;
    bottom: 0;
    width: 100%;
  }
  
  .footer-content {
    /* max-width: 1200px; */
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
  
  .footer-content p {
    margin: 0;
    font-size:1rem;
  }
  
  .footer-links {
    display: flex;
    gap: 15px;
  }
  
  .footer-links a {
    color: #ffffff;
    font-size: 1.5rem;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .footer-links a:hover {
    color: #007bff;
  }
  
  @media (max-width: 768px) {
    .footer-links {
      flex-direction: row;
      gap: 10px;
    }
  }
  