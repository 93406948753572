
.about-header {
    width: 100%;
    /* background-color: #000000; */
    background: linear-gradient(135deg, #e85ef4 0%, #2a5298 100%);
    color: #fff;
    padding: 20px 0;
    text-align: center;
    position: relative;
    top: 10px;
    left: 0;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}



.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

.about-title {
    font-size: 30px;
    font-weight: bold;
    text-transform: uppercase;
    margin: 0;
    letter-spacing: 2px;
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

/* Subtitle styling */
.about-subtitle {
    font-size: 18px;
    font-style: italic;
    margin-top: 15px;
    line-height: 1.6;
    max-width: 900px;
    margin: 15px auto 0;
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
    .about-title {
        font-size: 32px;
    }

    .about-subtitle {
        font-size: 16px;
        max-width: 600px;
    }
}

@media (max-width: 480px) {
    .about-title {
        font-size: 24px;
    }

    .about-subtitle {
        font-size: 14px;
        max-width: 90%;
    }
}
