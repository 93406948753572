/* Contact.css */
.contact-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f4f7f6;
  }
  
  .contact-form {
    background-color: #fff;
    border-radius: 8px;
    padding: 30px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 500px;
  }
  
  .contact-heading {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #007bff;
    border-bottom: 2px solid #007bff;
    padding-bottom: 10px;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
  
  .contact-form label {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 8px;
    color: #333;
    display: block;
  }
  
  .contact-form input,
  .contact-form textarea {
    width: 100%;
    padding: 12px 4px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    background-color: #f9f9f9;
    transition: border-color 0.3s ease;
  }
  
  .contact-form input:focus,
  .contact-form textarea:focus {
    border-color: #007bff;
    outline: none;
    background-color: #fff;
  }
  
  .contact-form textarea {
    resize: vertical;
  }
  
  .contact-form button {
    width: 100%;
    padding: 12px;
    background-color: #007bff;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .contact-form button:hover {
    background-color: #0056b3;
  }
  
  @media (max-width: 600px) {
    .contact-form {
      padding: 20px;
    }
  }
  