.main-section {
    padding: 40px 20px;
    background: #f9f9f9;
    border-radius: 15px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    /* max-width: 1200px; */
    margin: auto;
  }
  
  .intro-section {
    text-align: center;
    margin-bottom: 40px;
  }

  
  
  .intro-title {
    font-size: 2.5rem;
    font-weight: 700;
    color: #00274d;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  .intro-title i{
    color: red;
  }
  
  .intro-description {
    font-size: 1.2rem;
    color: #555;
    max-width: 800px;
    margin: 0 auto;
    line-height: 1.6;
  }
  
  .content-section {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  
  .content-box {
    background: #ffffff;
    border-radius: 10px;
    width: calc(33% - 20px);
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s, transform 0.3s;
  }
  
  .box-header {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 15px;
  }
  
  .box-header i {
    font-size: 1.5rem;
    color: #515cff;
  }
  
  .box-header h3 {
    font-size: 1.5rem;
    color: #007bff;
    margin: 0;
    border-bottom: 2px solid #007bff;
    padding-bottom: 10px;
  }
  
  .content-box p {
    font-size: 1rem;
    color: #333;
    line-height: 1.6;
  }
  
  .content-box:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    transform: translateY(-5px);
  }
  
  @media (max-width: 1024px) {
    .content-box {
      width: calc(50% - 20px);
    }
  }
  
  @media (max-width: 768px) {
    .content-box {
      width: calc(100% - 20px);
    }
  }
  